export const rules = {
  data() {
    return {
      required: (v) =>
        (v !== null && v !== undefined && v !== "" && v !== []) ||
        "This field is required.",
      requiredNotAllowZero: (v) =>
        (v !== null && v !== undefined && v !== "" && v !== [] && v !== 0) ||
        "This field is required.",
      number: (v) => !v || /^[1-9]\d*$/.test(v) || "Invalid format.",
      currency: (v) =>
        !v ||
        /^([-+]?[1-9][0-9]{0,2}(,[0-9]{3})*(\.[0-9]{0,2})?|[-+]?[1-9][0-9]{0,}(\.[0-9]{0,2})?|[-+]?0(\.[0-9]{0,2})?|[-+]?(\.[0-9]{1,2})?)$/.test(
          v
        ) ||
        "Invalid format.",
      percent: (v) =>
        !v ||
        /(^[-]?100(\.0{1,3})?$)|(^[-]?([1-9]([0-9])?|0)(\.[0-9]{1,3})?$)/g.test(
          v
        ) ||
        "Invalid format.",
      point: (v) => !v || /^[+-]?\d+(\.\d+)?$/g.test(v) || "Invalid format.",
      zipcode: (v) =>
        !v ||
        /^\d{5}$|^\d{5}-\d{4}$/.test(v) ||
        "Invalid format. (ex. 12345 or 12345-6789)",
      ssn: (v) =>
        !v ||
        /^(\d{3}-\d{2}-\d{4}|\d{9})$/.test(v) ||
        "Invalid format. (ex. 123456789 or 123-45-6789)",
      letter: (v) => !v || /^(\s*|[a-zA-Z\s])*$/.test(v) || "Invalid format.",
      email: (v) =>
        !v ||
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          v
        ) ||
        "Invalid format.",
      phone: (v) =>
        !v ||
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) ||
        "Invalid format.",
      creditScore: (v) =>
        !v ||
        (v >= 400 && v <= 850) ||
        "Credit Score must be between 400 and 850.",
      mmddyyyy: (v) =>
        !v ||
        /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(v) ||
        "Invalid format. (MM/DD/YYYY)",
      mmddyyyyhhss: (v) =>
        !v ||
        /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2} (20|21|22|23|[0-1]?\d):[0-5]?\d$/.test(
          v
        ) ||
        "Invalid format. (MM/DD/YYYY)",
      min6: (v) => v.length >= 6 || "Please enter at least 6 characters.",
      max50: (v) => v.length <= 50 || "Please enter than 50 characters",
    };
  },
};

export const common = {
  methods: {
    getValueByKey(keyValuePair, key) {
      return (
        keyValuePair.find((x) => x.key === key)?.displayName ?? "Undefined"
      );
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },
    openPdfNewTabWithBase64(base64PdfData, title) {
      // base64 데이터를 디코딩하여 바이너리 데이터로 변환
      const binaryPdf = atob(base64PdfData);

      // 바이너리 데이터를 Uint8Array로 변환
      const bytes = new Uint8Array(binaryPdf.length);
      for (let i = 0; i < binaryPdf.length; i++) {
        bytes[i] = binaryPdf.charCodeAt(i);
      }

      // PDF를 보여줄 객체 생성
      const blob = new Blob([bytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // 새로운 작은 윈도우 창 열기
      const newWindow = window.open("", "_blank", "width=600,height=800");
      newWindow.document.title = `${title}`;

      // 새로운 작은 윈도우 창에 PDF를 보여줄 <embed> 요소 추가
      const pdfEmbed = document.createElement("embed");
      pdfEmbed.src = url;
      pdfEmbed.width = "100%";
      pdfEmbed.height = "100%";
      newWindow.document.body.appendChild(pdfEmbed);
    },
    downloadFileWithUrl(dataurl, fileName, extension) {
      const element = document.createElement("a");
      element.setAttribute("href", dataurl);
      element.setAttribute("download", `${fileName}.${extension}`);
      // element.setAttribute('target', '_blank');
      element.click();
    },
    downloadFile(sourceName, sourceExtension, base64) {
      const element = document.createElement("a");
      const fileName = `${sourceName}.${sourceExtension}`;

      element.setAttribute(
        "href",
        `data:application/octet-stream;base64,${encodeURIComponent(base64)}`
      );
      element.setAttribute("download", fileName);
      element.click();
    },
    toFullAddress(street1, city, state, zip) {
      if (street1) {
        return `${street1}, ${city}, ${state} ${zip}`;
      } else {
        return null;
      }
    },
    getPrincipalAndInterest(loanAmt, term, interestRate, isInterestOnly) {
      const myLoanAmt = parseFloat(
        loanAmt.toString().replaceAll(",", "").replaceAll("$", "")
      );
      const myRate = parseFloat(
        (interestRate / 1200).toString().replaceAll(",", "").replaceAll("$", "")
      );
      const myTerm = parseInt(term);

      if (isInterestOnly) {
        return myLoanAmt * (interestRate / 1200.0);
      }

      if (myTerm == 0) {
        return myLoanAmt / myRate;
      }

      return (
        (myLoanAmt * (myRate * Math.pow(1 + myRate, myTerm))) /
        (Math.pow(1 + myRate, myTerm) - 1)
      );
    },
    getNameOnReport(application) {
      if (!application) return null;

      if (application.coBorrower != null) {
        if (application.borrower.lastName == application.coBorrower.lastName) {
          return `${application.borrower.firstName} and ${application.coBorrower.firstName} ${application.borrower.lastName}`;
        }
        return `${application.borrower.firstName} ${application.borrower.lastName} and ${application.coBorrower.firstName} ${application.coBorrower.lastName}`;
      }

      return `${application.borrower.firstName} ${application.borrower.lastName}`;
    },
  },
};
